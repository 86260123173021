/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Box } from '@chakra-ui/core';

import { bgColor, bgTextColor } from './constants';
import Header from './header';
import Footer from './footer';

const Layout = ({ children, disableHeader, disableFooter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      {disableHeader ? null : (
        <Header
          siteTitle={data.site.siteMetadata?.title || 'website'}
          bgColor={bgColor}
          textColor={bgTextColor}
        />
      )}
      <Box w="100%" p={3} textAlign="center" style={{ minHeight: 'calc(100vh - 2em)' }}>
        <main>{children}</main>
      </Box>
      {disableFooter ? null : (
        <Footer
          style={{ marginTop: '2em' }}
          bgColor={bgColor}
        />
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableHeader: PropTypes.bool,
  disableFooter: PropTypes.bool,
};
Layout.defaultProps = {
  disableHeader: false,
  disableFooter: false,
};

export default Layout;
