import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  Avatar, Stack, Box, Heading, Text, Image,
} from '@chakra-ui/core';

import LocalImage from '../imagepicker';

const Card = ({
  children,
  image,
  width,
  avatar,
  style,
  link,
}) => {
  const [over, onMouseOver] = useState(false);

  let imagecontent = null;
  if (image) {
    const img = LocalImage[image.name];
    imagecontent = img ? (
      <Box width={image.width}>
        <Image
          style={{ width: image.width || '100%', padding: '6px' }}
          // width={image.width || width || '350px'}
          objectFit="cover"
          rounded={image.radius || 'sm'}
          src={img.src}
          alt={img.alt}
        />
      </Box>
    ) : null;
  }
  let avatarcontent = null;
  if (avatar) {
    const img = LocalImage[avatar.name];
    avatarcontent = img ? (
      <Avatar
        name={img.alt}
        src={img.src}
        size="lg"
        style={{
          marginLeft: avatar.position === 'right' ? '1em' : '0em',
          marginRight: !avatar.position || avatar.position === 'left' ? '1em' : '0em',
        }}
      />
    ) : null;
  }

  const content = (
    <>
      <Stack align="center">
        {imagecontent}
      </Stack>
      {avatarcontent && (!avatar.position || avatar.position === 'left') ? avatarcontent : null}
      {children}
      {avatarcontent && avatar.position === 'right' ? avatarcontent : null}
    </>
  );

  return link ? (
    <Link to={link}>
      <Box
        as="button"
        onMouseEnter={() => onMouseOver(true)}
        onMouseLeave={() => onMouseOver(false)}
        shadow="sm"
        borderWidth="3px"
        rounded="lg"
        style={{
          margin: '12px',
          maxWidth: width,
          width: '70vw',
          opacity: over ? 0.7 : 1,
          ...style,
        }}
      >
        {content}
      </Box>
    </Link>
  ) : (
    <Box
      shadow="sm"
      borderWidth="1px"
      style={{
        margin: '12px',
        maxWidth: width,
        width: '95vw',
        ...style,
      }}
    >
      {content}
    </Box>
  );
};
Card.propTypes = {
  children: PropTypes.node,
  image: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    width: PropTypes.number,
    radius: PropTypes.string,
  }),
  avatar: PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.string,
  }),
  style: PropTypes.shape({}),
  width: PropTypes.number,
  logo: PropTypes.shape({}),
  link: PropTypes.string,
};
Card.defaultProps = {
  children: null,
  image: null,
  avatar: null,
  style: null,
  width: 750,
  logo: null,
  link: '',
};

export default Card;
