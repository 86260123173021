
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Image } from '@chakra-ui/core';

import LocalImage from '../imagepicker';
import { ImageCardText } from '../textfiles/contenttext';

const ImageCard = ({
  image,
  width,
  style,
}) => {
  if (image) {
    const img = LocalImage[image.name];
    return (
      <Box borderWidth="2px" rounded="md" width={{ sm: width }} style={{ ...style }}>
        <Image
          width={{ sm: width }}
          objectFit="cover"
          rounded={image.radius || 'md'}
          src={img.src}
          alt={img.alt}
          style={{ padding: '6px' }}
        />
        {img.author ? <Text fontSize="xs">{`${ImageCardText.credit}: ${img.author}`}</Text> : null}
        {img.source ? <Text fontSize="xs">{`${ImageCardText.source}: ${img.source}`}</Text> : null}
      </Box>
    );
  }
  return <div>{ImageCardText.notFound}</div>;
};
ImageCard.propTypes = {
  style: PropTypes.shape({}),
  image: PropTypes.shape({
    name: PropTypes.string,
    radius: PropTypes.string,
  }),
  width: PropTypes.number,
};
ImageCard.defaultProps = {
  style: {},
  image: null,
  width: 450,
};

export default ImageCard;
