import React from 'react';
import { Link } from 'gatsby';
import { Box, Button } from '@chakra-ui/core';

import { Page } from '../textfiles/maintexts';
import AboutDrawer from '../aboutdrawer';

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.onDrawerOpen = this.onDrawerOpen.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.state = {
      drawerOpen: false,
    };
  }

  onDrawerOpen(type) {
    let drawerContent = null;
    switch (type) {
      case 'about':
        drawerContent = <AboutDrawer onClose={this.onDrawerClose} />;
        break;
      default:
        break;
    }
    this.setState({ drawerOpen: true, drawerContent });
  }

  onDrawerClose() {
    this.setState({ drawerOpen: false, drawerContent: null });
  }

  render() {
    const { drawerOpen, drawerContent } = this.state;
    return (
      <>
        <Box bg="teal" w="100%" p={3} color="white" textAlign="center">
          <Link to="/">
            <Button w={150} m={1} variantColor="green">
              {Page.home}
            </Button>
          </Link>
          <Link to="/applications/">
            <Button w={150} m={1} variantColor="orange">
              {Page.applications}
            </Button>
          </Link>
          <Link to="/contact/">
            <Button w={150} m={1} variantColor="purple">
              {Page.contact}
            </Button>
          </Link>
          <Button
            w={150}
            m={1}
            variantColor="pink"
            onClick={() => this.onDrawerOpen('about')}
          >
            {Page.about}
          </Button>
        </Box>
        {drawerOpen ? drawerContent : null}
      </>
    );
  }
}

export default MainMenu;
