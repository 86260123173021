import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { Navigation } from '../textfiles/maintexts';

const Footer = ({
  style,
  content,
  bgColor,
  textColor,
}) => (
  <footer
    style={{
      background: bgColor,
      width: '100%',
      textAlign: 'right',
      color: textColor,
      ...style,
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '0.8rem 1.8rem',
      }}
    >
      <Link
        to="/"
        style={{
          textDecoration: 'none',
        }}
      >
        {Navigation.title}
      </Link>
      <h5>{content}</h5>
    </div>
  </footer>
);
Footer.propTypes = {
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  style: PropTypes.shape({}),
  content: PropTypes.shape({}),
};
Footer.defaultProps = {
  style: {},
  content: null,
  textColor: 'lightgrey',
};

export default Footer;
