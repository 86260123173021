import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Icon } from '@chakra-ui/core';

import Menu from '../navigation/mainmenu';

const Header = ({ siteTitle, bgColor, textColor }) => (
  <Box w="100%">
    <header
      style={{
        background: bgColor,
        marginBottom: '0.5rem',
      }}
    >
      <div
        style={{
          margin: '0 auto',
          padding: '1.1rem 1.0rem',
          textAlign: 'center',
        }}
      >
        <h1 style={{ textTransform: 'uppercase', fontSize: 'x-large' }}>
          <Icon name="sun" size="24px" color={textColor} />
          <Link
            to="/"
            style={{
              color: textColor,
              verticalAlign: 'middle',
              padding: '12px',
            }}
          >
            {siteTitle}
          </Link>
          <Icon name="moon" size="20px" color={textColor} />
        </h1>
      </div>
      <Menu />
    </header>
  </Box>
);

Header.propTypes = {
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
  textColor: 'black',
};

export default Header;
