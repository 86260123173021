import React from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  theme,
} from '@chakra-ui/core';

import ImageCard from './display/imagecard';
import Card from './display/card';
import LocalImage from './imagepicker';

import { AboutText } from './textfiles/contenttext';
import { Navigation } from './textfiles/maintexts';

function AboutDrawer({ onClose }) {
  const img = LocalImage.portrait;
  return (
    <Drawer
      isOpen
      placement="right"
      onClose={onClose}
      size="md"
      allowPinchZoom
    >
      <DrawerOverlay>
        <DrawerContent overflow="scroll">
          <DrawerCloseButton />
          <DrawerHeader
            as="h1"
            size="lg"
            m={1}
            style={{ color: theme.colors.pink[500] }}
          >
            {AboutText.title}
          </DrawerHeader>

          <DrawerBody style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Stack spacing={8} align="center" style={{ paddingLeft: '1rem' }}>
              <Grid templateColumns="2fr 8fr 2fr" gap={10} alignItems="center">
                <Box p={3}>
                  {img ? (
                    <Avatar
                      name={img.alt}
                      src={img.src}
                      size="lg"
                    />
                  ) : null}
                </Box>
                <Box p={3} textAlign="center">
                  <Heading fontSize="xl">
                    {AboutText.subtitle}
                  </Heading>
                </Box>
                <Box p={3} />
              </Grid>
            </Stack>
            <Stack spacing={8} align="center">
              <Card style={{ padding: '12px' }} width={450}>
                {AboutText.description}
              </Card>
            </Stack>
            <Stack spacing={8} align="center">
              <ImageCard image={{ name: 'ocean' }} width={450} style={{ margin: '6px' }} />
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {Navigation.goBack}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}

AboutDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AboutDrawer;
