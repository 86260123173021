// content texts for pages

export const Lorem = {
  ipsum: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
};

export const HomeText = {
  title: 'Accueil',
  welcome: `Bonjour et bienvenue!`,
  description: `Ce site est une expérience de couleurs et d'animations. Quelques mini applications et jeux vont bientôt être disponible.`,
};

export const AboutText = {
  title: 'À propos',
  subtitle: `L'Auteur`,
  description: `Bonjour je m'appelle Julien et je suis très heureux de vous présenter mon site web!`,
};

export const AppText = {
  title: 'Applications',
  cssgame: {
    name: 'Mini-jeu animé',
    desc: 'Un jeu basé sur du CSS',
  },
  nains: {
    name: 'Nain bleu',
    desc: 'Nain bleu ou Secret Santa, pour tirer au sort les cadeaux de Noël',
  },
  converter: {
    name: 'Convertisseur numérique',
    desc: 'Convertisseur binaire, hexadécimal, décimal, ...',
  },
  commingSoon: {
    name: 'Nouveautés pour bientôt',
    desc: 'Des mini app sont au rendez-vous prochainement',
  },
};

export const ContactText = {
  title: 'Contact',
  contact: 'Prendre contact ?',
  thanks: 'Merci',
};

export const ImageCardText = {
  credit: 'crédits',
  source: 'source',
  notFound: 'Image inexistante...',
};
