import ocean from '../images/ocean.jpg';
import borat from '../images/borat.jpg';
import portrait from '../images/portrait.png';

const LocalImage = {
  ocean: {
    src: ocean,
    alt: 'photo ocean',
    author: 'John Such',
    source: 'Unsplash',
  },
  astronaut: {
    src: borat,
    alt: 'double thumbs up borat',
    author: 'Borat',
  },
  portrait: {
    src: portrait,
    alt: 'Julien portrait',
    author: 'Julien',
  },
};

export default LocalImage;
