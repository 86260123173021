// Main texts for the app

export const Navigation = {
  title: 'Chiappinelli.ch',
  goBack: 'Retour',
};

export const ErrorText = {
  title: 'Erreur 404',
  subtitle: 'Page inexistante',
};

export const Page = {
  home: 'Accueil',
  applications: 'Applications',
  contact: 'Contact',
  about: 'À propos',
};

export const FormText = {
  submit: 'Envoyer',
  placeholder: 'placeholder',
  surname: {
    label: 'Surnom',
    helper: 'Ne pas remplir',
  },
  name: {
    label: 'Nom',
    helper: 'Prénom ou nom...',
  },
  email: {
    label: 'E-mail',
    helper: 'Adresse email...',
  },
  message: {
    label: 'Message',
    helper: 'Message...',
  },
  error: {
    mandatory: 'Champ obligatoire',
    email: `Format d'email invalide!`,
    text: 'Format invalide!',
    message: 'Caractère interdit!',
  },
};


export const FormConverterText = {
  bin: {
    label: 'Binary',
    helper: 'Binary format',
  },
  hex: {
    label: 'Hexadecimal',
    helper: 'Hexadecimal format',
  },
  dec: {
    label: 'Decimal',
    helper: 'Decimal format',
  },
  b64: {
    label: 'Base64',
    helper: 'Base 64 format',
  },
  ascii: {
    label: 'Ascii',
    helper: 'Ascii format',
  },
  bytes: {
    label: 'Bytes (decimal)',
    helper: 'Bytes decimal representation',
  },
};
